import { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useAuth } from '@backoffice/providers';
import { ProfileDto, UserDto, profileSchema } from '@backoffice/state/model';
import { updateUser } from '@backoffice/state/mutations';
import { getUser } from '@backoffice/state/queries';
import { useFlags, useLocales } from '@facephi/inphinite-common';
import { FormComponentProfile } from '@facephi/inphinite-user-management';
import {
  Button,
  DropdownOption,
  DropdownSearchOption,
  FlexContainer,
  InputUpload,
  Label,
  useTimezone,
  useToast,
} from '@facephi/ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ModalMail } from './ModalMail';
import {
  FormControlDropdown,
  FormControlDropdownSearch,
  FormProfile,
  FormProfileFooter,
  FormProfileInput,
  ProfileMessage,
} from './Styles';

type Props = {
  onSave?(): void;
  user: UserDto;
};

export const ProfileForm = ({ user }: Props) => {
  const { t, i18n } = useTranslation();
  const [sendMail, setSendMail] = useState<boolean>(false);
  const navigate = useNavigate();
  const { toastManager } = useToast();
  const { handleRefreshToken, saveDataUser } = useAuth();

  const [saveUser] = useMutation(updateUser);

  const { demo } = useFlags();

  const { setTimezone, timezone, options: rawTimezones } = useTimezone();
  const { locales: rawLocales } = useLocales();

  const locales: DropdownOption[] = useMemo(
    () =>
      rawLocales.map((item) => ({
        name: t(item),
        value: item,
      })),
    [rawLocales]
  );

  const timezones: DropdownSearchOption[] = useMemo(
    () =>
      rawTimezones.map((key: string) => ({
        name: key,
        value: key,
      })),
    [rawTimezones]
  );

  const handleSendMail = () => {
    setSendMail(true);
  };

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<FieldValues>({
    resolver: yupResolver(profileSchema),
    defaultValues: {
      name: user?.name,
      surname: user?.surname,
      email: user?.email,
      timezone: user.timezone ? user.timezone : timezone,
      language: i18n.language,
    },
  });

  const onSumbit = async (data: ProfileDto) => {
    await saveUser({
      variables: {
        userId: user.id,
        user: {
          roleId: user?.role?.id,
          personalInformation: {
            firstName: data.name,
            lastName: data.surname,
            avatar: data.avatar,
          },
          userPreferences: {
            language: data.language,
            timezone: data.timezone,
          },
        },
      },
      refetchQueries: [
        {
          query: getUser,
          variables: { userId: user.id },
        },
      ],
      onCompleted: ({
        user: {
          userPreferences: { timezone: userTimezone, language },
        },
      }) => {
        userTimezone && setTimezone(userTimezone);
        language && i18n.changeLanguage(language);
      },
    });

    handleRefreshToken();

    saveDataUser({
      name: data.name as string,
      surname: data.surname,
      avatar: data.avatar,
      language: data.language,
      timezone: data.timezone,
    });

    toastManager({
      type: 'success',
      message: t('Updated user data'),
      duration: 3000,
      testId: 'save-profile',
    });
  };

  return (
    <>
      <Label fontSize="14" semibold>
        {t('Welcome, {{username}}', { username: user.name })}
      </Label>
      <ProfileMessage fontSize="14">
        {t('Here you can manage your information and your privacy settings')}
      </ProfileMessage>
      <FormProfile onSubmit={handleSubmit(onSumbit)} id="user-profile-form">
        <FormComponentProfile title={t('Personal Information')}>
          <Controller
            control={control}
            name="avatar"
            render={({ field, fieldState }) => (
              <InputUpload {...field} {...fieldState} base64 />
            )}
          />
          <FlexContainer columnGap="5.4">
            <FormProfileInput
              type="text"
              {...register('name')}
              label={t('Name')}
              testId="input-name"
              errorLabel={errors.name?.message && t(errors.name.message)}
            />
            <FormProfileInput
              type="text"
              {...register('surname')}
              label={t('Surname')}
              testId="input-surname"
              errorLabel={errors.surname?.message && t(errors.surname.message)}
            />
          </FlexContainer>
        </FormComponentProfile>
        <FormComponentProfile title={t('Account settings')}>
          <FormProfileInput
            label={t('Email')}
            type="text"
            {...register('email')}
            disabled
            iconLeft="Envelope"
            errorLabel={errors.email?.message && t(errors.email.message)}
          />
          {demo && (
            <FlexContainer flexDirection="column" rowGap="1.6">
              <Label fontSize="14">
                {t(
                  'To change your password click on the button to receive an email'
                )}
              </Label>
              <FlexContainer columnGap="5.4" alignItems="flex-end">
                <FormProfileInput label={t('Password')} iconLeft="Lock" />
                <Button onClick={handleSendMail}>{t('Change password')}</Button>
              </FlexContainer>
            </FlexContainer>
          )}
        </FormComponentProfile>
        <FormComponentProfile title={t('Languages & Timezone')}>
          <FlexContainer columnGap="5.4">
            <Controller
              control={control}
              name="language"
              render={({ field, fieldState }) => (
                <FormControlDropdown
                  {...field}
                  {...fieldState}
                  label={t('Language')}
                  options={locales}
                  iconLeft="Flag"
                  errorLabel={
                    errors.language?.message && t(errors.language.message)
                  }
                />
              )}
            />
            <Controller
              control={control}
              name="timezone"
              render={({ field, fieldState }) => (
                <FormControlDropdownSearch
                  {...field}
                  {...fieldState}
                  label={t('Timezone')}
                  options={timezones}
                  iconLeft="MapTrifold"
                  locale={i18n.language}
                  errorLabel={
                    errors.timezone?.message && t(errors.timezone.message)
                  }
                />
              )}
            />
          </FlexContainer>
        </FormComponentProfile>
        <FormProfileFooter columnGap="2.4" justifyContent="flex-end">
          <Button variant="text" onClick={() => navigate(-1)}>
            {t('Cancel')}
          </Button>
          <Button type="submit" testId="button-save" form="user-profile-form">
            {t('Save')}
          </Button>
        </FormProfileFooter>
      </FormProfile>
      <ModalMail
        onChangeShow={(sendMail) => setSendMail(sendMail)}
        show={sendMail}
        onCreate={() => {}}
        onResend={() => {}}
      />
    </>
  );
};
